import { lazy } from "react";
const HomePage = lazy(() => import("./homepage"));
const AboutPage = lazy(() => import("./about"));
const ServicesPage = lazy(() => import("./services"));
const PortfolioPage = lazy(() => import("./portfolio"));
const ContactPage = lazy(() => import("./contact"));
const ProductsPage = lazy(() => import("./products"));

const TamplariePVCPage = lazy(() => import("./products/tamplarie-pvc"));
const PBT500 = lazy(() => import("./products/tamplarie-pvc/pbt500"));
const K600 = lazy(() => import("./products/tamplarie-pvc/k600"));
const SL73 = lazy(() => import("./products/tamplarie-pvc/sl73"));
const SL82 = lazy(() => import("./products/tamplarie-pvc/sl82"));
const SL92 = lazy(() => import("./products/tamplarie-pvc/sl92"));

const TamplarieAluminiuPage = lazy(() => import("./products/tamplarie-aluminiu"));
const PR26 = lazy(() => import("./products/tamplarie-aluminiu/pr26"));
const PR50 = lazy(() => import("./products/tamplarie-aluminiu/pr50"));
const PR52 = lazy(() => import("./products/tamplarie-aluminiu/pr52"));
const COR60 = lazy(() => import("./products/tamplarie-aluminiu/cor60"));
const COR80 = lazy(() => import("./products/tamplarie-aluminiu/cor80"));
const Eskimos = lazy(() => import("./products/tamplarie-aluminiu/eskimos"));
const Sliding = lazy(() => import("./products/tamplarie-aluminiu/sliding"));

const TamplarieLemnPage = lazy(() => import("./products/tamplarie-lemn"));
const PanelOrnamentalPage = lazy(() => import("./products/panel-ornamental"));
const UsaAntifocPage = lazy(() => import("./products/usa-antifoc"));
const UsaMetalicaPage = lazy(() => import("./products/usa-metalica"));
const BalustradaPage = lazy(() => import("./products/balustrada"));
const PereteCortinaPage = lazy(() => import("./products/perete-cortina"));
const SticlaPage = lazy(() => import("./products/sticla"));
const RulouExteriorPage = lazy(() => import("./products/rulou-exterior"));
const RoletaTextilaPage = lazy(() => import("./products/roleta-textila"));
const PergolaPage = lazy(() => import("./products/pergola"));
const CopertinaPage = lazy(() => import("./products/copertina"));
const UsaGarajPage = lazy(() => import("./products/usa-garaj"));
const PereteAntivantPage = lazy(() => import("./products/perete-antivant"));
const PlasaInsectePage = lazy(() => import("./products/plasa-insecte"));
const FerestreSiUsiPage = lazy(() => import("./ferestre-si-usi"));
const CompartimentariPage = lazy(() => import("./compartimentari"));
const SistemeUmbrirePage = lazy(() => import("./sisteme-de-umbrire"));
const ExteriorPage = lazy(() => import("./exterior"));

const PlataRate = lazy(() => import("./plata-rate"));
const PoliticaCookiesPage = lazy(() => import("./politica-cookies"));
const PoliticaConfidentialitatePage = lazy(() => import("./politica-confidentialitate"));

export const routes = [
	{
		id: 1,
		path: "/",
		Element: HomePage,
		priority: 1,
	},
	{
		id: 2,
		path: "/despre-noi",
		Element: AboutPage,
		priority: 0.5,
	},
	{
		id: 3,
		path: "/servicii",
		Element: ServicesPage,
		priority: 0.5,
	},
	{
		id: 4,
		path: "/produse",
		Element: ProductsPage,
		priority: 0.7,
	},
	{
		id: 5,
		path: "/portofoliu",
		Element: PortfolioPage,
		priority: 0.4,
	},
	{
		id: 6,
		path: "/contact",
		Element: ContactPage,
		priority: 0.6,
	},

	{
		id: 7,
		path: "/produse/tamplarie-pvc",
		Element: TamplariePVCPage,
		priority: 0.7,
	},
	{
		id: 8,
		path: "/produse/tamplarie-pvc/pbt-thermplus500",
		Element: PBT500,
		priority: 0.7,
	},
	{
		id: 9,
		path: "/produse/tamplarie-pvc/klass-k600",
		Element: K600,
		priority: 0.7,
	},
	{
		id: 10,
		path: "/produse/tamplarie-pvc/salamander-bluevolution73",
		Element: SL73,
		priority: 0.7,
	},
	{
		id: 11,
		path: "/produse/tamplarie-pvc/salamander-bluevolution82",
		Element: SL82,
		priority: 0.7,
	},
	{
		id: 12,
		path: "/produse/tamplarie-pvc/salamander-bluevolution92",
		Element: SL92,
		priority: 0.7,
	},
	{
		id: 13,
		path: "/produse/tamplarie-aluminiu",
		Element: TamplarieAluminiuPage,
		priority: 0.7,
	},
	{
		id: 14,
		path: "/produse/tamplarie-aluminiu/profilco-pr26",
		Element: PR26,
		priority: 0.7,
	},
	{
		id: 15,
		path: "/produse/tamplarie-aluminiu/profilco-pr50",
		Element: PR50,
		priority: 0.7,
	},
	{
		id: 16,
		path: "/produse/tamplarie-aluminiu/profilco-pr52",
		Element: PR52,
		priority: 0.7,
	},
	{
		id: 17,
		path: "/produse/tamplarie-aluminiu/cortizo-cor60",
		Element: COR60,
		priority: 0.7,
	},
	{
		id: 18,
		path: "/produse/tamplarie-aluminiu/cortizo-cor80",
		Element: COR80,
		priority: 0.7,
	},
	{
		id: 19,
		path: "/produse/tamplarie-aluminiu/altest-eskimos",
		Element: Eskimos,
		priority: 0.7,
	},
	{
		id: 20,
		path: "/produse/tamplarie-aluminiu/altest-sliding",
		Element: Sliding,
		priority: 0.7,
	},
	{
		id: 21,
		path: "/produse/tamplarie-lemn-stratificat",
		Element: TamplarieLemnPage,
		priority: 0.7,
	},
	{
		id: 22,
		path: "/produse/panel-ornamental-aluminiu",
		Element: PanelOrnamentalPage,
		priority: 0.7,
	},
	{
		id: 23,
		path: "/produse/usa-antifoc",
		Element: UsaAntifocPage,
		priority: 0.7,
	},
	{
		id: 24,
		path: "/produse/usa-metalica",
		Element: UsaMetalicaPage,
		priority: 0.7,
	},
	{
		id: 25,
		path: "/produse/balustrada",
		Element: BalustradaPage,
		priority: 0.7,
	},
	{
		id: 26,
		path: "/produse/perete-cortina",
		Element: PereteCortinaPage,
		priority: 0.7,
	},
	{
		id: 27,
		path: "/produse/sticla",
		Element: SticlaPage,
		priority: 0.7,
	},
	{
		id: 28,
		path: "/produse/rulou-exterior",
		Element: RulouExteriorPage,
		priority: 0.7,
	},
	{
		id: 29,
		path: "/produse/roleta-textila",
		Element: RoletaTextilaPage,
		priority: 0.7,
	},
	{
		id: 30,
		path: "/produse/pergola",
		Element: PergolaPage,
		priority: 0.7,
	},
	{
		id: 31,
		path: "/produse/copertina",
		Element: CopertinaPage,
		priority: 0.7,
	},
	{
		id: 32,
		path: "/produse/usa-garaj",
		Element: UsaGarajPage,
		priority: 0.7,
	},
	{
		id: 33,
		path: "/produse/perete-antivant",
		Element: PereteAntivantPage,
		priority: 0.7,
	},
	{
		id: 34,
		path: "/produse/plasa-insecte",
		Element: PlasaInsectePage,
		priority: 0.7,
	},
	{
		id: 35,
		path: "/ferestre-si-usi",
		Element: FerestreSiUsiPage,
		priority: 0.9,
	},
	{
		id: 36,
		path: "/compartimentari",
		Element: CompartimentariPage,
		priority: 0.9,
	},
	{
		id: 37,
		path: "/sisteme-de-umbrire",
		Element: SistemeUmbrirePage,
		priority: 0.9,
	},
	{
		id: 38,
		path: "/exterior",
		Element: ExteriorPage,
		priority: 0.9,
	},

	{
		id: 39,
		path: "/plata-in-rate",
		Element: PlataRate,
		priority: 0.25,
	},
	{
		id: 40,
		path: "/politica-cookies",
		Element: PoliticaCookiesPage,
		priority: 0.1,
	},
	{
		id: 41,
		path: "/politica-confidentialitate",
		Element: PoliticaConfidentialitatePage,
		priority: 0.1,
	},
];
