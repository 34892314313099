import { lazy, Suspense, useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { routes } from "./routes";
import Loading from "./components/app/loading";
const Page404 = lazy(() => import("./routes/404"));

function App() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});
	}, [location.pathname]);

	useEffect(() => {
		const link1 = document.createElement("link");
		link1.href = "https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600&display=swap";
		link1.rel = "stylesheet";
		link1.defer = true;
		document.head.appendChild(link1);

		const link2 = document.createElement("link");
		link2.href = "https://fonts.googleapis.com/css2?family=Trirong:wght@300;400;500;600;700&display=swap";
		link2.rel = "stylesheet";
		link2.defer = true;
		document.head.appendChild(link2);
	}, []);

	return (
		<Suspense fallback={<Loading />}>
			<Routes>
				{routes.map(({ id, path, Element }) => (
					<Route path={path} element={<Element />} key={id} />
				))}
				<Route element={<Page404 />} path="*" />
			</Routes>

			<Toaster
				position="center-center"
				reverseOrder={false}
				gutter={8}
				toastOptions={{
					duration: 5000,
				}}
			/>
		</Suspense>
	);
}

export default App;
