import Logo from "../../images/logo.svg";

function Loading() {
	return (
		<div className="fixed top-0 left-0 h-screen w-screen flex flex-col items-center justify-center bg-white z-[60]">
			<img
				src={Logo}
				alt="I&I Business Team - Tâmplărie PVC, Aluminiu și Lemn Stratificat București"
				className="h-32 w-32 object-contain"
			/>
		</div>
	);
}

export default Loading;
